import React, { useCallback, useState, useEffect } from "react";
import { FieldTitle, useInput, useTranslate } from "react-admin";
import { LocalizationProvider } from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/min/locales";

const Picker = ({ Component, ...props }) => {
  const [locale, setLocale] = useState("");
  const {
    label,
    source,
    resource,
    className,
    isRequired,
    providerOptions,
    fullWidth,
    onChange,
    helperText,
    defaultValue,
  } = props;

  const translate = useTranslate();
  const [value, setValue] = useState();
  const { input, meta } = useInput({ source });
  const { touched, error } = meta;

  useEffect(() => {
    const locale = navigator.language;
    moment().locale(locale).isValid() ? setLocale(locale) : setLocale("en");
  }, []);

  useEffect(() => {
    if (input && input.value) {
      setValue(input.value);
    }
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, input]);

  const handleChange = useCallback(
    (value) => {
      onChange(value);
       Date.parse(value)
        ? input.onChange(value.toISOString())
        : input.onChange(null);
    },
    [input, onChange]
  );

  const parseValue = (value) => {
    const date = value.split(/[^0-9]/);
    let newDate =new Date(Date.UTC(date[0], date[1] - 1, date[2], date[3], date[4], date[5]));
    let usDate = newDate.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return usDate;
  };

  return (
    <div style={{ width: "255px",
      minWidth: "255px",
      margin: "8px 15px 4px 0",
      height: "64px",}}
    >

    <div className={className}>
      <LocalizationProvider {...providerOptions} locale={locale}>
        <Component
          {...props}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          margin="normal"
          error={!!(touched && error)}
          helperText={touched && error}
          value={value ? parseValue(value) : null}
          clearText={translate("ra.action.clear_input_value")}
          cancelText={translate("ra.action.cancel")}
          onChange={(date) => handleChange(date)}
          onBlur={() =>
            input.onBlur(
              input.value ? new Date(input.value).toISOString() : null
            )
          }
          renderInput={(props) => (
            <TextField
              {...props}
              margin="normal"
              variant="outlined"
              fullWidth={fullWidth}
              helperText={helperText}
              error={!!(touched && error)}
            />
          )}
        />
      </LocalizationProvider>
    </div>
    </div>
  );
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
  providerOptions: {
    dateAdapter: MomentUtils,
  },
  fullWidth: false,
  onChange: () => {},
};

export default Picker;
