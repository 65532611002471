import React from "react";

const CompanyCreateLazy = React.lazy(() => import('./CompanyCreate'));
const CompanyEditLazy = React.lazy(() => import('./CompanyEdit'));
const CompanyListLazy = React.lazy(() => import('./CompanyList'));
const CompanyShowLazy = React.lazy(() => import('./CompanyShow'));


const Company = {
  list: CompanyListLazy,
  create: CompanyCreateLazy,
  edit: CompanyEditLazy,
  show: CompanyShowLazy,
};

export default Company;
