import React from "react";

const matchyListLazy= React.lazy(() => import('./matchyList'));


const matchyCewl = {
  list: matchyListLazy

};

export default matchyCewl;

