import { HttpError } from "react-admin";

/**
 * Returns an RFC 7807 error parser which translate them into React-Admin expected format.
 * @param {options} Options
 * @param {options.message} A function which will be called with the error and must return a message. Defaults to extracting the detail property of the error body returned by React-Admin HttpClient.
 * @param {options.status} A function which will be called with the error and must return a status. Defaults to extracting the status property of the error body returned by React-Admin HttpClient.
 *
 * @example <caption>Basic usage</caption>
 * const parseError = parseRFC7807Error();
 *
 * httpClient(url, options)
 *     .then((response) => {
 *         return convertHttpResponse(response, type, resource, params);
 *     })
 *     .catch(error => parseError(error));
 *     // Could be written like this as well
 *     .catch(parseError);
 *
 * @example <caption>Customize how to get the message</caption>
 * const parseError = parseRFC7807Error({
 *     message: function(error) {
 *         return error.body.detail;
 *     }
 * });
 *
 * httpClient(url, options)
 *     .then((response) => {
 *         return convertHttpResponse(response, type, resource, params);
 *     })
 *     .catch(error => parseError(error));
 *
 * @example <caption>Customize how to get the status</caption>
 * const parseError = parseRFC7807Error({
 *     status: function(error) {
 *         return error.body.status;
 *     }
 * });
 *
 * httpClient(url, options)
 *     .then((response) => {
 *         return convertHttpResponse(response, type, resource, params);
 *     })
 *     .catch(error => parseError(error));
 *
 * @example <caption>Customize how to get the message and the status</caption>
 * const parseError = parseRFC7807Error({
 *     message: function(error) {
 *         return error.body.detail;
 *     },
 *     status: function(error) {
 *         return error.body.status;
 *     }
 * });
 *
 * httpClient(url, options)
 *     .then((response) => {
 *         return convertHttpResponse(response, type, resource, params);
 *     })
 *     .catch(error => parseError(error));
 *
 * @returns A function which takes a single argument: the error.
 */
export const parseRFC7807Error = ({
  message = defaultParseMessage,
  status = defaultParseStatus,
} = {}) => {
  return function (error) {
    // If the response body included by React-admin in the error
    // follow the RFC, parse the notification message from it
    if (error.body && error.body.type && error.body.detail) {
      throw new HttpError(message(error), status(error), error.body);
    }
    // Otherwise, we just rethrow the original error
    throw error;
  };
};

const defaultParseMessage = (error) => error.body.detail;
const defaultParseStatus = (error) => error.body.status;
