import React from "react";
import { FaCheck } from "react-icons/fa";

const smallerText = {
  fontSize: "90%",
};

const Requirement = ({ htmlFor, isvalid, validMessage, invalidMessage }) => (
  <label
    htmlFor={htmlFor}
    className={!isvalid ? `invalid` : `valid`}
    style={smallerText}
  >
    <FaCheck />
    <span>{!isvalid ? invalidMessage : validMessage}</span>
  </label>
);

export default Requirement;
