import React from "react";
import "normalize.css";
import "../registration/css/styles.css";

import StyledWrapper from "../registration/css/Styles";

import FancyForgotPasswordForm from "./FancyForgotPasswordForm";

export default function ForgotPassword() {
  return (
    <StyledWrapper>
      <FancyForgotPasswordForm />
    </StyledWrapper>
  );
}
