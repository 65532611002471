import React from "react";

const NotificationGroupListLazy = React.lazy(() => import('./NotificationGroupList'));
const NotificationGroupCreateLazy = React.lazy(() => import('./NotificationGroupCreate'));
const NotificationGroupEditLazy = React.lazy(() => import('./NotificationGroupEdit'));
const NotificationGroupShowLazy = React.lazy(() => import('./NotificationGroupShow'));


const NotificationGroup = {
  list: NotificationGroupListLazy,
  create: NotificationGroupCreateLazy,
  edit: NotificationGroupEditLazy,
  show: NotificationGroupShowLazy,
};

export default NotificationGroup;
