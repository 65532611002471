import React from "react";

const TokenListLazy = React.lazy(() => import('./TokenList'));
const TokenCreateLazy = React.lazy(() => import('./TokenCreate'));
const TokenEditLazy = React.lazy(() => import('./TokenEdit'));
const TokenShowLazy = React.lazy(() => import('./TokenShow'));


const Token = {
  list: TokenListLazy,
  create: TokenCreateLazy,
  edit: TokenEditLazy,
  show: TokenShowLazy,
};

export default Token;
