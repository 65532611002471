import React from "react";

const UserCreateLazy = React.lazy(() => import('./UserCreate'));
const UserEditLazy = React.lazy(() => import('./UserEdit'));
const UserListLazy = React.lazy(() => import('./userList'));
const UserShowLazy = React.lazy(() => import('./UserShow'));


const User = {
  list: UserListLazy,
  create: UserCreateLazy,
  edit: UserEditLazy,
  show: UserShowLazy,
};

export default User;
