import React, { useState, useEffect } from "react";
import { Formik } from "formik";

import Requirements from "./Requirements";
import { usePreferences } from "@react-admin/ra-preferences";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// our fonts
import { createTheme , ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import myEndpoints from "../config";

// put in a font theme
const theme = createTheme ({
  typography: {
    fontFamily: ["Arial, Data70"].join(","),
  },
});

const divStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

// for the dropdown
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      fontSize: "100%",
    },
  },
  field: {
    padding: "16px",
    border: "1px solid #ccc",
    marginTop: "16px",
    width: "100%",
  },
}));

// For the snackbox alert
const snackBoxStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FancyChangePassword = (props) => {
  const classes = useStyles();
  const snackBox = snackBoxStyles();
  const [long, longEnough] = useState(false);
  const [number, hasNumber] = useState(false);
  const [mixed, hasMixed] = useState(false);
  const [special, hasSpecial] = useState(false);

  // for showing error message from server, keeping it consistent
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [preferences, setPreferences] = usePreferences("theme");

  useEffect(() => {
    const theme = preferences;
    window.location.href.includes("login") && setPreferences("light");
    return () => setPreferences(theme);
  }, [preferences,setPreferences]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /**
   * from: https://github.com/github/fetch/issues/203
   * Parses the JSON returned by a network request
   *
   * @param  {object} response A response from a network request
   *
   * @return {object}          The parsed JSON, status from the response
   */
  function parseJSON(response) {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        })
      )
    );
  }

  /**
   * from: https://github.com/github/fetch/issues/203
   * Requests a URL, returning a promise
   *
   * @param  {string} url       The URL we want to request
   * @param  {object} [options] The options we want to pass to "fetch"
   *
   * @return {Promise}           The request promise
   */
  function request(url, options) {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then(parseJSON)
        .then((response) => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json);
        })
        .catch((error) =>
          reject({
            status: 503,
            title: "Network Error",
            detail: error.message,
          })
        );
    });
  }

  // TODO fix this so we don't need state for variables as we are using Formik
  // TODO get the timezone and then work out the country and country region

  return (
    <div style={divStyle}>
      <Container
        component={"main"}
        maxWidth="xs"
        style={{ background: preferences === "light" ? "#fff" : "#424242" }}
      >
        <Formik
          initialValues={{
            new_password: "",
            old_password: "",
            backendError: "",
          }}
          validate={(values) => {
            values.new_password.length < 8
              ? longEnough(false)
              : longEnough(true);
            !/\d/.test(values.new_password)
              ? hasNumber(false)
              : hasNumber(true);
            !/(?=.*[a-z])(?=.*[A-Z])/.test(values.new_password)
              ? hasMixed(false)
              : hasMixed(true);
            !/[!@#$%^&*]/.test(values.new_password)
              ? hasSpecial(false)
              : hasSpecial(true);
          }}
          onSubmit={(
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            let errors = {};

            // make popup error allowed again
            setOpenSnackbar(true);

            if (!long || !number || !mixed || !special)
              errors.new_password =
                "Your password needs min 8 characters, a special character, upper and lower case and at least one number ";

            // If the errors object is empty then we've successfully met all the requirements
            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              // here we go!

              // options.headers = new Headers({ Accept: 'application/json' });

              const token = localStorage.getItem("access_token");
              //options.headers.set('Authorization', `Bearer ${token}`);

              // create the post
              console.log(values);
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(values),
              };

              const endPoint = localStorage.getItem("change_password");
              // no need to send JWT as it doesn't require anything there
              // https://jasonwatmore.com/post/2020/11/02/react-fetch-http-put-request-examples

              window.location.href.includes("login")
                ? request(endPoint, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(values),
                  })
                    .then((result) => {
                      localStorage.removeItem("change_password");
                      window.location = "/#/login";
                    })
                    .catch((error_json, err) => {
                      var the_result = error_json.title !== undefined;
                      var the_common_error = error_json.message !== undefined;
                      console.log(the_result);
                      console.log(the_common_error);
                      if (the_common_error) {
                        console.log("Common error");
                        console.log(error_json.message);
                        setStatus(error_json.message);
                      } else if (the_result) {
                        console.log("A formatted error response from backend");
                        console.log(error_json);
                        console.log(error_json.title);
                        setStatus(error_json.title);
                      } else {
                        setStatus("An error has occurred.");
                      }
                    })
                : request(
                    myEndpoints().REACT_APP_CRUD_ADMIN_CHANGE_PASSWORD_ENDPOINT,
                    requestOptions
                  )
                    .then((result) => {
                      console.log("success");
                      console.log(result);
                      window.location = "/#";
                    })
                    .catch((error_json, err) => {
                      var the_result = error_json.title !== undefined;
                      var the_common_error = error_json.message !== undefined;
                      console.log(the_result);
                      console.log(the_common_error);
                      if (the_common_error) {
                        console.log("Common error");
                        console.log(error_json.message);
                        setStatus(error_json.message);
                      } else if (the_result) {
                        console.log("A formatted error response from backend");
                        console.log(error_json);
                        console.log(error_json.title);
                        setStatus(error_json.title);
                      } else {
                        setStatus("An error has occurred.");
                      }
                    });
              // alert(`Great, we've created an account for ${values.username}`);
              // resetForm(); // Reset form for the example
            } else {
              // not sure what this else is for now!
              setStatus("Make sure you meet the password complexity"); // setStatus(errors);
            }

            setSubmitting(false);
          }}
        >
          {({
            errors,
            values,
            status,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <div>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box m={0} pt={1}>
                  <Typography
                    component="h4"
                    variant="h5"
                    align={"center"}
                    fontFamily={"Data70"}
                  >
                    Change Password
                  </Typography>
                </Box>
              </ThemeProvider>
              <div className={"reg"}>
                <Box ml={1} m={5} pt={0}>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="old_password">
                      What is your old password
                    </label>
                    <input
                      className={classes.field}
                      id="old_password"
                      type="password"
                      name="old_password"
                      onChange={handleChange}
                      required
                      value={values.oldPassword}
                    />
                    <label htmlFor="new_password">
                      What do you want your password to be?
                    </label>
                    <input
                      className={classes.field}
                      id="new_password"
                      type="password"
                      name="new_password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      required
                      value={values.new_password}
                    />
                    {errors.new_password && (
                      <label className="error" htmlFor="new_password">
                        Sorry! {errors.new_password}
                      </label>
                    )}
                    <div>
                      <Requirements
                        long={long}
                        number={number}
                        mixed={mixed}
                        special={special}
                      />
                    </div>
                    <Box m={-3} p={0} pb={2}>
                      <Grid container justify="center">
                        <button
                          type="submit"
                          value="Create account"
                          disabled={isSubmitting}
                        >
                          Change Password
                        </button>
                      </Grid>
                    </Box>
                    {status && (
                      <Box m={0} p={-2}>
                        <div style={{ color: "black" }}>
                          <Typography
                            align="center"
                            fontSize="10"
                            color="primary"
                          >
                            Sorry! {status}
                          </Typography>
                        </div>
                      </Box>
                    )}
                    {status && (
                      <div className={snackBox.root}>
                        <Snackbar
                          open={openSnackbar}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert onClose={handleClose} severity="error">
                            Sorry! {status}
                          </Alert>
                        </Snackbar>
                      </div>
                    )}
                  </form>
                </Box>
              </div>
            </div>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default FancyChangePassword;
