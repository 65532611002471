import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// to be used if account comes back with Locked out
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useLogin, useNotify } from "react-admin";
import Paper from "@material-ui/core/Paper";

import { Icon } from "@material-ui/core";
import YourLogo from "./images/ctci_icon_only.svg";
import Image from "./images/warp_speed.jpg";
import Notification from "../layout/Notification";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ctci.ai">
        CTCI
      </Link>{" "}
      {`${new Date().getFullYear()} .`}
    </Typography>
  );
}

const divStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${Image})`,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 1.0)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: "#3f51b5",
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  input: {
    "& input:-webkit-autofill": {
      boxShadow: "0 0 0 500px white inset",
    },
  },
  text: {
    color: "#3f51b5",
    display: "block",
    width: "max-content",
    "& > span > span": {
      color: "#3f51b5",
    },
  },
}));

const MyCoolLoginPage = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  localStorage.setItem("front_end_host_url", window.location.origin);

  const submit = (e) => {
    e.preventDefault();
    if (remember && username) {
      localStorage.setItem("username", username);
      localStorage.setItem("remember", remember);
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("remember");
    }
    login({ username, password }).catch((error) => {
      notify(error.message, "error");
    });
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("username");
    const isChecked = localStorage.getItem("remember");
    if (isChecked) {
      setRemember(isChecked);
      if (loggedInUser) {
        setUsername(loggedInUser);
      }
    }
  }, []);

  return (
    <div style={divStyle}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Box m={1} pt={2} pb={1}>
            <div align={"center"}>
              <Icon>
                <img src={YourLogo} height={25} width={25} alt="logo" />
              </Icon>
            </div>
          </Box>
          <Box m={-2} pt={-3}>
            <Typography component="h1" variant="h5" align={"center"}>
              This is the way
            </Typography>
          </Box>
          <Box m={2} pt={-1}>
            <form className={classes.form} onSubmit={submit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email Address"
                name="email"
                autoComplete="username"
                autoFocus
                value={username && username}
                onChange={(e) => setUsername(e.target.value)}
                className={classes.input}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                className={classes.input}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                onChange={(e) => setRemember(e.target.checked)}
                label="Remember me"
                checked={!!remember}
                className={classes.text}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#/forgot_password"
                    variant="body2"
                    className={classes.text}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="/#/register"
                    variant="body2"
                    className={classes.text}
                  >
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Box mt={4}>
            <Copyright />
          </Box>
        </Paper>
      </Container>
      <Notification />
    </div>
  );
};

export default MyCoolLoginPage;
