import React from "react";
import "normalize.css";
import "../registration/css/styles.css";

import FancyChangePassword from "./FancyChangePassword";
import StyledWrapper from "../registration/css/Styles";

export default function ChangePassword() {
  return (
    <StyledWrapper>
      <FancyChangePassword />
    </StyledWrapper>
  );
}
