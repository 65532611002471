import React, { useState, useEffect } from "react";
import { usePermissions } from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Link,
  List,
  ListItem,
} from "@material-ui/core";
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// Grab the configuration endpoints
import myEndpoints from "../config";
import { useNotify, useRedirect } from "ra-core";
const ReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles({
  mainTitle: {
    fontFamily: "Barlow",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    fontFamily: "Barlow",
  },
  link: {
    display: "block",
    fontWeight: "bold",
    marginBottom: "15px",
  },
  list: {
    marginBottom: "15px",
  },
  list_item: {
    paddingTop: 0,
    paddingBottom: "10px",
  },
  hidden_block: {
    display: "none",
  },
});

const base_documentation = myEndpoints().REACT_APP_DOCUMENTATION_URL;

const Dashboard = () => {
  const [userRole, setUserRole] = useState();
  const { permissions } = usePermissions();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  // To check whether token expired or not
  const validateToken = () => {
    const access_token = localStorage.getItem("access_token");
    const myHeaders = new Headers({ Authorization: "Bearer " + access_token });

    try {
      // TODO DETERMINE WHY WE HAVE 48 HERE??
      // THIS COULD BE THE BUG THAT SOMETIMES IT RETURNS AN INVALID LOGIN REQUEST THEN NEED TO LOGIN AGAIN.
      fetch(myEndpoints().REACT_APP_CRUD_ADMIN_ENDPOINT + "/company/" + "48", {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            (data.status && data.status === 401) ||
            (data.status_code && data.status_code === 401)
          ) {
            // we need to log back in
            notify("Logged in token no longer valid", "Error");
            redirect("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch {
      console.log("Caught the err");
    }
  }

  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    permissions && permissions.rls && setUserRole(permissions.rls);
  }, [permissions]);

  return (
    <ReactGridLayout
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 }}
      rowHeight={100}
      width={1200}
      isResizable={false}
      verticalCompact
    >
      <Card key="a" data-grid={{ x: 0, y: 0, w: 4, h: 1, static: true }} >
        <CardHeader
          classes={{ title: classes.title }}
          align="center"
          title="CTCI Documentation"
        />
      </Card>
      <div key="b" data-grid={{ x: 0, y: 1, w: 1, h: 4.35 }}>
        <Card>
          <CardHeader
            classes={{ title: classes.title }}
            title="CEWL Documentation"
          />
          <CardContent>
            <Link
              href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/194248845/Getting+started"}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              Getting Started Guide
            </Link>
            <Link
              href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/323452932/CEWL+User+Guide"}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              CEWL User Guide
            </Link>
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/325517390/Notification+Filters"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Filters
              </Link>
            </List>
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/325353473/Notification+Groups"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Notification Groups
              </Link>
            </List>
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/325484563/Notification+Delivery"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Notification Delivery
              </Link>
            </List>
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329416770/Processed"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Processing
              </Link>
            </List>
          </CardContent>
          <CardContent>Keeping it CEWL!</CardContent>
        </Card>
      </div>
      <div key="c" data-grid={{ x: 1, y: 1, w: 1, h: 3.6 }}>
        <Card>
          <CardHeader
            classes={{ title: classes.header }}
            title="Legit Documentation"
          />
          <CardContent>
            <Link
              href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/362807297/LEGIT+User+Guide"}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              Legit User Guide
            </Link>
          </CardContent>
          <CardContent>IOCs for free - now that is Legit!</CardContent>
        </Card>
      </div>
      <div key="d" data-grid={{ x: 2, y: 1, w: 1, h: 3.5 }}>
        <Card>
          <CardHeader
            classes={{ title: classes.header }}
            title="API Documentation"
          />
          <CardContent>
            <Link
              href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/399376385/API+Developers+Guide"}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              API Documentation
            </Link>

          </CardContent>
          <CardContent>Hit up the API!</CardContent>
        </Card>
      </div>
      <div key="e" data-grid={{ x: 3, y: 1, w: 1, h: 8 }}>
        <Card>
          <CardHeader
            classes={{ title: classes.header }}
            title="Portal Documentation"
          />
          <CardContent>
            <Link
              href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/340525116/CTCI+Portal"}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              Introduction to Portal
            </Link>
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329417156/Users"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Users
              </Link>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329417156/Users"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Adding Users
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329417156/Users#Delete-a-User"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Deleting Users
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329417156/Users#How-to-Edit-a-User"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Updating Users
                </Link>
              </ListItem>
            </List>

            {/* will need different menu items for different roles  */}
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329285785/Company"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Company
              </Link>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329285785/Company#Companies-within-the-CTCI-Portal"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Adding Companies
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329285785/Company#Delete-a-Company"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Deleting Companies
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329285785/Company#How-to-Edit-a-Company"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Updating Companies
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329416709/Email+Domains"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Managing Email Domains
                </Link>
              </ListItem>
            </List>
            <List className={classes.list}>
              <Link
                href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329416854/Tokens"}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                API Tokens
              </Link>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://swagger.cewl.ctci.ai/"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Swagger API Documentation
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329416854/Tokens#Tokens-within-the-CTCI-Portal"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Adding API Tokens
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329416854/Tokens#Delete-an-API-Token-entry"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Deleting Tokens
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/329416854/Tokens#How-to-Edit-an-API-Token-Entry"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Updating Tokens
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                >
                  How to call it in Python, Javascript
                </Link>
              </ListItem>
            </List>
            <List>
              <Link
                href={base_documentation}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Introduction to Notifications
              </Link>
              <ListItem className={classes.list_item}>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                >
                  Introduction to Notifications
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                >
                  Creating Notifications
                </Link>
              </ListItem>
              <ListItem className={classes.list_item}>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                >
                  Creating Notification Delivery
                </Link>
              </ListItem>
            </List>
          </CardContent>
          <CardContent>Making things better</CardContent>
        </Card>
      </div>
      <div key="j" data-grid={{ x: 1, y: 1, w: 1, h: 3.6 }}>
        <Card>
          <CardHeader
            classes={{ title: classes.header }}
            title="Release Notes"
          />
          <CardContent>
            <Link
              href={"https://ctci.atlassian.net/wiki/spaces/CTCIDOC/pages/457113836/Release+Notes"}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              Release Notes
            </Link>

          </CardContent>
          <CardContent>Check the latest release notes!</CardContent>
        </Card>
      </div>
      {/* only for Distributors, super-admin, OEM and resellers */}

      <div key="f" data-grid={{ x: 0, y: 2, w: 1, h: 3.5 }}>
        {userRole === "distributor_admin" ||
          userRole === "super_admin" ||
          userRole === "oem_admin" ||
          userRole === "reseller_admin" ? (
          <Card>
            <CardHeader
              classes={{ title: classes.header }}
              title="Deal Registration"
            />
            <CardContent>
              <Link
                href={base_documentation}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Deal Registration
              </Link>
              <List>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  Creating a Deal
                </Link>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Removing a Deal
                  </Link>
                </ListItem>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Managing a Deal
                  </Link>
                </ListItem>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Start Customer Trial
                  </Link>
                </ListItem>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notification of Approval
                  </Link>
                </ListItem>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Length of Deal
                  </Link>
                </ListItem>
              </List>
            </CardContent>
            <CardContent>Deal it up!</CardContent>
          </Card>
        ) : null}
      </div>

      {/* only for OEMS/ MSSPs   */}
      <div key="g" data-grid={{ x: 1, y: 2, w: 1, h: 2.7 }}>
        {userRole === "oem_admin" && (
          <Card>
            <CardHeader
              classes={{ title: classes.header }}
              title="OEM/MSSP Management"
            />
            <CardContent>
              <Link
                href={base_documentation}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                OEM Management
              </Link>
              <List>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  Trialing Companies
                </Link>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Managing Companies
                  </Link>
                </ListItem>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Expiry Handling
                  </Link>
                </ListItem>
              </List>
            </CardContent>
            <CardContent>Deal it up!</CardContent>
          </Card>
        )}
      </div>

      {/* Partners */}
      <div key="h" data-grid={{ x: 2, y: 2, w: 1, h: 2.7 }}>
        {userRole === "partner_admin" && (
          <Card>
            <CardHeader
              classes={{ title: classes.header }}
              title="Partner Management"
            />
            <CardContent>
              <Link
                href={base_documentation}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Partner Management
              </Link>
              <List>
                <Link
                  href={base_documentation}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  Adding Partners
                </Link>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Managing Partners
                  </Link>
                </ListItem>
                <ListItem className={classes.list_item}>
                  <Link
                    href={base_documentation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Expiry Handling
                  </Link>
                </ListItem>
              </List>
            </CardContent>
            <CardContent>Keep the world safer!</CardContent>
          </Card>
        )}
      </div>
      <div key="i" data-grid={{ x: 0, y: 4, w: 4, h: 1 }}>
        <Card>
          <CardHeader
            classes={{ title: classes.header }}
            title="Documentation"
          />
          <CardContent>
            Our documentation is stored in the cloud version of Atlassian
            Confluence
          </CardContent>
        </Card>
      </div>
    </ReactGridLayout>
  );
};

export default Dashboard;
