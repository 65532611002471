import React, { useEffect } from "react";
import { useRedirect } from "react-admin";


// Users the user's profile edit for the user
const EditProfileForm = (props) => {

  // for redirect to user edit page
  const redirect = useRedirect();

  useEffect(() => {
    redirect("/user/" + localStorage.getItem("user_id", "0"));
  });

  return <div></div>;
};

export default EditProfileForm;
