import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SettingsIcon from "@material-ui/icons/Settings";

const MyUserMenu = (props) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/edit_profile"
        primaryText="Edit Profile"
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink
        to="/change_password"
        primaryText="Change Password"
        leftIcon={<LockOpenIcon />}
      />
    </UserMenu>
  );
};

export default React.memo(MyUserMenu);
