import React from "react";

const LegitLazy = React.lazy(() => import('./Legit'));


const Ioc = {
  list: LegitLazy,
};

export default Ioc;
