export const CreateWithMutation = async (
  values,
  mutate,
  notify,
  redirect,
  resource
) => {
  try {
    await mutate(
      {
        type: "create",
        resource: resource,
        payload: {data: values},
      },
      {returnPromise: true}
    );
    notify("Saved", "info");
    redirect(`/${resource}`);
  } catch (error) {
    if (error && error.body && error.body.parameter) {
      if (error.body.detail) {
        notify(error.body.detail, "error");
        return error.body.parameter;
      } else {
        return error.body.parameter;
      }
    } else {
      notify("Error processing request", "error");
    }
  }
};

export const EditWithMutation = async (
  values,
  mutate,
  notify,
  redirect,
  resource
) => {
  try {
    await mutate(
      {
        type: "update",
        resource: resource,
        payload: {id: values.id, data: values},
      },
      {returnPromise: true}
    );
    notify("Saved", "success");
    redirect(`/${resource}`);
  } catch (error) {
    if (error && error.body && error.body.parameter) {
      if (error.body.detail) {
        notify(error.body.detail, "error");
        return error.body.parameter;
      } else {
        return error.body.parameter;
      }
    } else {
      notify("Error processing request", "error");
    }
  }
};
