import CewlCreate from "./CewlCreate";
import CewlEdit from "./CewlEdit";
import CewlList from "./CewlList";


const Cewl = {
  create: CewlCreate,
  edit: CewlEdit,
  list: CewlList,
};

export default Cewl;
