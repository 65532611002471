import React from 'react';
import { LinearProgress } from "@material-ui/core";


const style = {
  width: "30%",
  margin: "auto auto",
};

const ProgressBar = () => {
  return (
    <div style = {style} >
      <LinearProgress />
    </div >
  );
};

export default ProgressBar;
