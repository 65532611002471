// in src/customRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import EditProfile from "./user/EditProfile";
import ChangePassword from "./auth/ChangePassword";
import ForgotPassword from "./auth/ForgotPassword";
const RegisterLazy = React.lazy(() => import('./registration/Register'));


const customRoutes = [
  <Route exact path="/edit_profile" component={EditProfile} />,
  <Route exact path="/change_password" component={ChangePassword} />,
  <Route exact path="/register" component={RegisterLazy} noLayout />,
  <Route exact path="/forgot_password" component={ForgotPassword} noLayout />,
  <Route
    exact
    path="/login/change_password"
    component={ChangePassword}
    noLayout
  />,
];

export default customRoutes;
