import React from "react";

const NotificationDeliveryListLazy = React.lazy(() => import('./NotificationDeliveryList'));
const NotificationDeliveryCreateLazy = React.lazy(() => import('./NotificationDeliveryCreate'));
const NotificationDeliveryEditLazy = React.lazy(() => import('./NotificationDeliveryEdit'));
const NotificationDeliveryShowLazy = React.lazy(() => import('./NotificationDeliveryShow'));


const NotificationDelivery = {
  list: NotificationDeliveryListLazy,
  create: NotificationDeliveryCreateLazy,
  edit: NotificationDeliveryEditLazy,
  show: NotificationDeliveryShowLazy,
};

export default NotificationDelivery;
