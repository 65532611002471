import React from "react";
import Requirement from "./Requirement";

const Requirements = ({ long, number, mixed, special }) => (
  <section className="strength-meter">
    <h4>Password Requirements</h4>
    <Requirement
      htmlFor="new_password"
      isvalid={long}
      invalidMessage="We like long passwords, at least 8 characters if you could"
      validMessage="Sweet, that's long enough for us!"
    />
    <Requirement
      htmlFor="new_password"
      isvalid={number}
      invalidMessage="Make it tricky to guess, adding a number makes it more secure"
      validMessage="Nice, now you're safer than the average Joe"
    />
    <Requirement
      htmlFor="new_password"
      isvalid={mixed}
      invalidMessage="We like passwords that have mixed capitals  "
      validMessage="You are mixing upper and lower case like a DJ"
    />
    <Requirement
      htmlFor="new_password"
      isvalid={special}
      invalidMessage="Make it real hard to guess with some special characters"
      validMessage="Nice, you must be a Legit cybersecurity expert!"
    />
  </section>
);

export default Requirements;
