import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";

const Title = ({ value, record }) => {
  const [values, setValues] = useState("");

  useEffect(() => {
    value && setValues(value);
    record && record.username && setValues(record.username);
    record && record.company_name && setValues(record.company_name);
    record && record.email_domain && setValues(record.email_domain);
    record &&
      record.notification_group_name &&
      setValues(record.notification_group_name);
    record &&
      record.notification_delivery_name &&
      setValues(record.notification_delivery_name);
    record && record.filter_name && setValues(record.filter_name);
    record && record.token_name && setValues(record.token_name);
    record && record.vendor && setValues(record.vendor);
    record && record.id_ref && setValues(record.id);
  }, [value, record]);

  return <Box ml={16}>{values}</Box>;
};

export default Title;
