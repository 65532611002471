import React from "react";

const NotificationListLazy = React.lazy(() => import('./NotificationList'));
const NotificationCreateLazy = React.lazy(() => import('./NotificationCreate'));
const NotificationEditLazy = React.lazy(() => import('./NotificationEdit'));
const NotificationShowLazy = React.lazy(() => import('./NotificationShow'));


const Notification = {
  list: NotificationListLazy,
  create: NotificationCreateLazy,
  edit: NotificationEditLazy,
  show: NotificationShowLazy,
};

export default Notification;
