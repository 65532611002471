import React from "react";

const ProcessedListLazy = React.lazy(() => import('./ProcessedList'));
const ProcessedCreateLazy = React.lazy(() => import('./ProcessedCreate'));
const ProcessedEditLazy = React.lazy(() => import('./ProcessedEdit'));
const ProcessedShowLazy = React.lazy(() => import('./ProcessedShow'));


const Processed = {
  list: ProcessedListLazy,
  create: ProcessedCreateLazy,
  edit: ProcessedEditLazy,
  show: ProcessedShowLazy,
};

export default Processed;
