// in src/MyAppBar.js
import React, { useState, useEffect } from "react";
import { AppBar, usePermissions } from "react-admin";
import MyUserMenu from "./MyUserMenu";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { ToggleThemeButton } from "@react-admin/ra-preferences";

import logo from "./assest/main-logo.png";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: -10,
  },
  appbar: {
    backgroundColor: "#000000",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: "70px",
    marginLeft: -35,
  },
  role: {
    fontSize: "13px",
    fontWeight: "bold",
  },
});

const MyAppBar = (props) => {
  const [userRole, setUserRole] = useState();
  const classes = useStyles();
  const { permissions } = usePermissions();

  useEffect(() => {
    permissions && permissions.rls && setUserRole(permissions.rls);
  }, [permissions]);

  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <Box mt={-2} mb={-2} pl={-4} p={-3}>
        <Toolbar>
          <img src={logo} alt="logo" className={classes.logo} />
        </Toolbar>
      </Box>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Typography color="inherit" className={classes.role}>
        {`User role - ${userRole}`}
      </Typography>
      <ToggleThemeButton />
    </AppBar>
  );
};

export default React.memo(MyAppBar);
