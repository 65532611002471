import React from "react";

const EmailDomainCreateLazy = React.lazy(() => import('./EmailDomainCreate'));
const EmailDomainListLazy = React.lazy(() => import('./EmailDomainList'));
const EmailDomainEditLazy = React.lazy(() => import('./EmailDomainEdit'));
const EmailDomainShowLazy = React.lazy(() => import('./EmailDomainShow'));


const EmailDomain = {
  list: EmailDomainListLazy,
  create: EmailDomainCreateLazy,
  edit: EmailDomainEditLazy,
  show: EmailDomainShowLazy,

};

export default EmailDomain;

