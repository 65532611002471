import React, { useState } from "react";
import { Formik } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// our fonts
import { createTheme , ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import myEndpoints from "../config";

// put in a font theme
const theme = createTheme ({
  typography: {
    fontFamily: ["Arial, Data70"].join(","),
  },
});

const divStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

// for the dropdown
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 10,
    marginBottom: 10,
    paddingRight: 15,
    display: "inline-block",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 1.0)",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      fontSize: "100%",
    },
  },
  field: {
    padding: "16px",
    border: "1px solid #ccc",
    marginTop: "16px",
    width: "100%",
  },
}));

// For the snackbox alert
const snackBoxStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FancyForgotPasswordForm = () => {
  const classes = useStyles();
  const snackBox = snackBoxStyles();

  // for showing error message from server, keeping it consistent
  const [openSnackbar, setOpenSnackbar] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  /**
   * from: https://github.com/github/fetch/issues/203
   * Parses the JSON returned by a network request
   *
   * @param  {object} response A response from a network request
   *
   * @return {object}          The parsed JSON, status from the response
   */
  function parseJSON(response) {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        })
      )
    );
  }

  /**
   * from: https://github.com/github/fetch/issues/203
   * Requests a URL, returning a promise
   *
   * @param  {string} url       The URL we want to request
   * @param  {object} [options] The options we want to pass to "fetch"
   *
   * @return {Promise}           The request promise
   */
  function request(url, options) {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then(parseJSON)
        .then((response) => {
          if (response.ok) {
            return resolve(response.json);
          }
          return reject(response.json);
        })
        .catch((error) =>
          reject({
            status: 503,
            title: "Network Error",
            detail: error.message,
          })
        );
    });
  }

  return (
    <div style={divStyle}>
      <Container component={"main"} maxWidth="xs">
        <Formik
          initialValues={{ username: "", backendError: "" }}
          validate={() => {}}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            let errors = {};

            // make popup error allowed again
            setOpenSnackbar(true);

            // If the errors object is empty then we've successfully met all the requirements
            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              // here we go!

              // options.headers = new Headers({ Accept: 'application/json' });

              // const token = localStorage.getItem('access_token');
              localStorage.removeItem("access_token");
              //options.headers.set('Authorization', `Bearer ${token}`);

              // create the post
              console.log(values);
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(values),
              };

              // no need to send JWT as it doesn't require anything there
              // https://jasonwatmore.com/post/2020/11/02/react-fetch-http-put-request-examples
              request(
                myEndpoints().REACT_APP_CRUD_ADMIN_FORGOT_PASSWORD_ENDPOINT,
                requestOptions
              )
                .then((result) => {
                  console.log("success");
                  console.log(result);
                  setStatus(
                    "Please check your email for a new temporary password"
                  );
                  setTimeout(function () {
                    setSubmitting(true);
                    window.location = "/#/login";
                    setSubmitting(false);
                  }, 3000);
                })
                .catch((error_json) => {
                  var the_result = error_json.title !== undefined;
                  var the_common_error = error_json.message !== undefined;
                  console.log(the_result);
                  console.log(the_common_error);
                  if (the_common_error) {
                    console.log("Common error");
                    console.log(error_json.message);
                    setStatus(error_json.message);
                  } else if (the_result) {
                    console.log("A formatted error response from backend");
                    console.log(error_json);
                    console.log(error_json.title);
                    setStatus(error_json.title);
                  } else {
                    setStatus("An error has occurred.");
                  }
                });
              // alert(`Great, we've created an account for ${values.username}`);
              // resetForm(); // Reset form for the example
            } else {
              // not sure what this else is for now!
              setStatus("An error has occurred"); // setStatus(errors);
            }
            setStatus("Please check your email for a new temporary password");
            setTimeout(function () {
              setSubmitting(true);
              window.location = "/#/login";
              setSubmitting(false);
            }, 6000);
          }}
        >
          {({
            errors,
            values,
            status,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <div>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box m={0} pt={1}>
                  <Typography
                    component="h4"
                    variant="h5"
                    align={"center"}
                    fontFamily={"Data70"}
                  >
                    Forgot Password
                  </Typography>
                </Box>
              </ThemeProvider>
              <div className={"reg"}>
                <Box m={5} pt={0}>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="email">
                      Where do you live on the Internet?
                    </label>
                    <input
                      className={classes.field}
                      id="username"
                      type="email"
                      name="username"
                      onChange={handleChange}
                      required
                      value={values.email}
                    />
                    {errors.username && (
                      <label className="error" htmlFor="new_password">
                        {errors.username}
                      </label>
                    )}

                    <Box m={0} p={0} pb={3}>
                      <Grid container justifyContent ="center">
                        <button
                          type="submit"
                          value="Create account"
                          disabled={isSubmitting}
                        >
                          Forgot Password
                        </button>
                      </Grid>
                    </Box>
                    {status && (
                      <Box m={0} p={-2}>
                        <div style={{ color: "black" }}>
                          <Typography
                            align="center"
                            fontSize="10"
                            color="primary"
                          >
                            {status}
                          </Typography>
                        </div>
                      </Box>
                    )}

                    {status && (
                      <div className={snackBox.root}>
                        <Snackbar
                          open={openSnackbar}
                          autoHideDuration={3000}
                          onClose={handleClose}
                        >
                          <Alert onClose={handleClose} severity="success">
                            {status}
                          </Alert>
                        </Snackbar>
                      </div>
                    )}
                  </form>
                </Box>
              </div>
            </div>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default FancyForgotPasswordForm;
