import React from "react";

const DealRegistrationListLazy = React.lazy(() => import('./DealRegistrationList'));
const DealRegistrationCreateLazy = React.lazy(() => import('./DealRegistrationCreate'));
const DealRegistrationEditLazy = React.lazy(() => import('./DealRegistrationEdit'));
const DealRegistrationShowLazy = React.lazy(() => import('./DealRegistrationShow'));


const DealRegistration = {
  list: DealRegistrationListLazy,
  create: DealRegistrationCreateLazy,
  edit: DealRegistrationEditLazy,
  show: DealRegistrationShowLazy,
};

export default DealRegistration;
