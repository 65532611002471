import React from "react";
import { SaveButton, DeleteButton, Button, Toolbar } from "react-admin";
import { Link } from "react-router-dom";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";


const ToolBar = ({ basePath, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
      <Button
        component={Link}
        to={basePath ? basePath : "/"}
        label="Back"
        style={{ margin: "0 auto 0 10px" }}
      >
        <KeyboardReturnIcon />
      </Button>
      <DeleteButton label="Delete" />
    </Toolbar>
  );
};

export default ToolBar;
