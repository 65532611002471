import React from "react";

const NotificationFilterListLazy = React.lazy(() => import('./NotificationFilterList'));
const NotificationFilterCreateLazy = React.lazy(() => import('./NotificationFilterCreate'));
const NotificationFilterEditLazy = React.lazy(() => import('./NotificationFilterEdit'));
const NotificationFilterShowLazy = React.lazy(() => import('./NotificationFilterShow'));


const NotificationFilters = {
  list: NotificationFilterListLazy,
  create: NotificationFilterCreateLazy,
  edit: NotificationFilterEditLazy,
  show: NotificationFilterShowLazy,
};

export default NotificationFilters;
