import React from "react";
import EditProfileForm from "./EditProfileForm";
import Paper from "@material-ui/core/Paper";

export default function Register() {
  return (
    <Paper>
      <EditProfileForm />
    </Paper>
  );
}
